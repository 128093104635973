<template>
	<v-card flat class="referrals-table" :class="computedClass">
		<QDataTableHeader class="referrals-table__header">
			<template #left>
				<p class="referrals-table__header-title">{{ title }}</p>
			</template>

			<template #right>
				<v-text-field
					v-model="computedSearch"
					:disabled="loading"
					append-icon="icon-q-search"
					label="Search Bird-Dog Referrals"
					dark dense single-line hide-details clearable
					class="referrals-table__header-search"
					@keyup="maybeSearch"
					@click:append="$emit('search')"
				/>
			</template>
		</QDataTableHeader>

		<div class="referrals-table__content">
			<div class="referrals-table__options" :class="showFilters && 'options--expanded'">
				<v-row justify="space-between">
					<v-col cols="auto">
						<v-btn
							text
							:disabled="loading"
							class="bird-dog-tracking__text-btn"
							@click="toggleFilters"
						>
							<v-icon>fas fa-filter</v-icon>
							<v-divider class="mx-2" vertical />
							Filter
						</v-btn>
					</v-col>
					<v-col cols="auto">
						<v-btn
							text
							:disabled="loading || !computedItems.length"
							class="bird-dog-tracking__text-btn"
							@click="$emit('export-results')"
						>
							<v-icon>fas fa-file-export</v-icon>
							<v-divider class="mx-2" vertical />
							Export Results
						</v-btn>
					</v-col>
				</v-row>

				<BirdDogReferralTableFilters
					v-show="showFilters"
					v-model="computedFilters"
					:default-filters="defaultFilters"
					:show-filters="showFilters"
					justify="start"
				/>
			</div>

			<QExpandableDataTable
				:key="tableKey"
				:headers="visibleHeaders"
				:items="computedItems"
				:page.sync="computedOptions.page"
				:items-per-page.sync="computedOptions.itemsPerPage"
				:sort-by.sync="computedOptions.sortBy"
				:sort-desc.sync="computedOptions.sortDesc"
				:server-items-length="serverItemsLength"
				:expanded.sync="expanded"
				:item-key="itemUniqueKey"
				:loading="loading"
				:hide-default-header="isMobileTableView"
				class="referrals-table__table"
				:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
				:show-expand="showExpand"
			>
				<template v-if="hasAdminPermissions" v-slot:item.ReferralSearch="{ item, value }">
					<div :class="!isMobileView && 'nowrap'">
						<router-link v-if="item.ReferringAgentCode" :to="`/agents/${item.ReferringAgentCode}`" class="bird-dog-tracking__link">{{ value || 'go to agent details' }}</router-link>
						<span v-else>{{ value || 'NA' }}</span>
					</div>
				</template>

				<template v-slot:item.tableActions="{ item }">
					<div class="nowrap">
						<v-btn color="q_blue_1" small fab icon :disabled="loading || error" @click.stop="showItemNotes(item)">
							<v-icon>icon-file-text-q</v-icon>
						</v-btn>
						<v-btn v-if="!hasAdminPermissions" color="q_blue_1" class="ml-2" small fab icon :disabled="loading || error" @click.stop="showItemInfo(item)">
							<v-icon>icon-eye-q</v-icon>
						</v-btn>
						<v-btn v-if="hasAdminPermissions" color="q_blue_1" class="ml-2" small fab icon :disabled="loading || error" @click.stop="editReferralInfo(item)">
							<v-icon>icon-edit-q</v-icon>
						</v-btn>
					</div>
				</template>

				<template v-if="showExpand" v-slot:expand="{ item }">
					<div class="referrals-table__expand-wrapper">
						<v-simple-table v-if="isMobileTableView">
							<tbody>
								<tr v-for="header in expandedHeaders" :key="header.value">
									<td class="text-start cell-title">{{ header.text }}</td>
									<td class="text-end">{{ item[header.value] }}</td>
								</tr>
							</tbody>
						</v-simple-table>
						<template v-else>
							<v-simple-table v-if="expandedTable" dense class="referrals-table__line-table">
								<thead>
									<tr>
										<th v-for="h in expandedHeaders" :key="h.value">
											<span>{{ h.text }}</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td v-for="h in expandedHeaders" :key="h.value">
											<span class="nowrap">{{ item[h.value] }}</span>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
							<div v-else class="referrals-table__line-flex">
								<div v-for="h in expandedHeaders" :key="h.value">
									<span class="cell-title">{{ h.text }}</span>:
									<span class="referrals-table__line-flex-content">{{ item[h.value] }}</span>
								</div>
							</div>
						</template>
					</div>
				</template>
			</QExpandableDataTable>
		</div>

		<BirdDogItemNotes
			v-if="doShowItemNotes"
			v-model="doShowItemNotes"
			:data="currentItem"
			:has-admin-permissions="hasAdminPermissions"
		/>

		<BirdDogItemInfo
			v-if="doShowItemInfo"
			v-model="doShowItemInfo"
			:data="currentItem"
		/>
	</v-card>
</template>

<script>
import moment from 'moment'

import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import BirdDogReferralTableFilters from './BirdDogReferralTableFilters.vue'
import BirdDogItemNotes from './BirdDogItemNotes.vue'
import BirdDogItemInfo from './BirdDogItemInfo.vue'

import { agentTableConfig, staffTableConfig } from '../config/birdDogConfigs.js'

export default {
    components: {
        QDataTableHeader,
        QExpandableDataTable,
		BirdDogReferralTableFilters,
		BirdDogItemInfo,
		BirdDogItemNotes,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
		items: {
			type: Array,
			default: () => [],
		},
		options: {
			type: Object,
			required: true,
		},
		filters: {
			type: Object,
			required: true,
		},
		serverItemsLength: {
			type: Number,
			default: -1,
		},
		hasAdminPermissions: {
			type: Boolean,
			default: false,
		},
		expandedTable: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		title: {
            type: String,
            default: 'Bird-Dog Referrals',
        },
		defaultFilters: Object,
    },
    data () {
        return {
			showFilters: false,
            expanded: [],
			tableKey: 0,
			itemUniqueKey: 'ID',
			currentItem: null,

			doShowItemNotes: false,
			doShowItemInfo: false,
        }
    },
    computed: {
		computedSearch: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		computedFilters: {
			get () { return this.filters },
			set (v) { this.$emit('update-filters', v) },
		},
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		computedClass () {
			return [
				this.isMobileView && 'referrals-table--mobile',
				this.isTabletView && 'referrals-table--tablet',
			]
		},
		config () {
			return this.hasAdminPermissions ? staffTableConfig : agentTableConfig
		},
		headers () {
			return this.config.map(item => ({
				text: item.label,
				value: item.key,
				class: item.type === 'currency' ? 'text-end' : '',
				cellClass: item.type === 'currency' ? 'text-end' : '',
				width: item.width,
				...(typeof item.sortable === 'boolean' && { sortable: item.sortable }),
			}))
		},
		visibleHeaders () {
			return this.isMobileTableView
				? this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).mobileVisible)
				: this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).visible !== false)
		},
		expandedHeaders () {
			return this.headers.filter(({ value }) => !this.visibleHeaders.some(h => h.value === value))
		},
		showExpand () {
			return this.expandedHeaders.length !== 0
		},
        exportFields () {
			const headers = this.config.map(({ key }) => key).filter(item => item !== 'tableActions')
            return [this.itemUniqueKey, ...headers]
        },
		computedItems () {
			return this.items.map(item => ({
				...item,
				...this.config.reduce((acc, conf) => {
					acc[conf.key] = this.formatItem(item[conf.key], conf.key)
					return acc
				}, {}),
				ReferralSearch: item.ReferralSearch || item.ReferringAgentCode,
				Principal: [item.PrincipalFirstName, item.PrincipalLastName].filter(Boolean).join(' ') || 'NA'
			}))
		},
    },
    watch: {
		isMobileTableView () {
			this.tableKey += 1
		},
		items () {
			this.expanded = []
			this.currentItem = null
		},
		doShowItemNotes (v) {
			if (!v) {
				this.currentItem = null
			}
		},
		doShowItemInfo (v) {
			if (!v) {
				this.currentItem = null
			}
		},
    },
    methods: {
		toggleFilters () {
            this.showFilters = !this.showFilters
        },
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatItem (value, headerKey) {
			if (this.checkNullish(value)) { return '-' }
			const config = this.config.find(({ key }) => key === headerKey)
			const valueSign = value < 0 ? '-' : ''
			if (config.type === 'currency') {
				const format = config.rounded ? '($0,0)' : '($0,0.00)'
				return valueSign + this.formatNumber(Math.abs(value), format)
			}
			if (config.type === 'percent') {
				const format = config.rounded ? '(0)' : '(0.00)'
				return valueSign + this.formatNumber(Math.abs(value), format) + '%'
			}
			if (config.type === 'date') {
				return config.format && config.toFormat
					? moment(value, config.format).format(config.toFormat)
					: value
			}
			return value
		},
		showItemNotes (computedItem) {
			const item = this.items.find(({ ID }) => ID === computedItem.ID)
			this.doShowItemNotes = true
			this.currentItem = item
		},
		showItemInfo (computedItem) {
			const item = this.items.find(({ ID }) => ID === computedItem.ID)
			this.doShowItemInfo = true
			this.currentItem = item
		},
		editReferralInfo (computedItem) {
			const item = this.items.find(({ ID }) => ID === computedItem.ID)
			this.$emit('edit-referral', { ...item })
		},
		maybeSearch (event) {
            if (event.keyCode !== 13) { return }
            // if (!this.computedSearch || this.computedSearch.length < 3) { return }
			this.$emit('search')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: referrals-table;
$header-height: 4.5rem;

.#{$block} {
	--width-search: 25rem;

	&--tablet,
	&--mobile {
		--width-search: unset;

		.#{$block}__table ::v-deep {
			thead tr th {
				height: 32px;
				text-wrap: wrap;
			}

			tr:not(.v-data-table__progress) th,
			tr td {
				padding: 0 0.5rem;
			}

			.v-data-footer {
				padding: 0;

				.v-data-footer__select {
					margin-right: 2rem;

					.v-select {
						margin-left: 1rem;
					}
				}

				.v-data-footer__pagination {
					margin: 0 1rem 0 0;
				}

				.v-btn {
					margin: 0;
				}
			}
		}
	}

	&__header {
		background-color: $color-header !important;
		min-height: $header-height;
		display: flex;
		width: 100%;
		align-items: center;
		border-top-right-radius: $border-radius !important;
		border-top-left-radius: $border-radius !important;

		&-title {
			margin-bottom: 0;
			font-size: 1.25rem;
			font-weight: bold;
			line-height: 1.3;
			color: $color-white;
		}
	}

	&__header-search {
		margin-top: 0;
		margin-left: auto;
		max-width: var(--width-search);
	}

	&__content {
		padding-bottom: 1rem;
		background-color: $color-white;
		border: $border;
		border-top: none;
	}

	&__options {
		margin: 0 1rem;
		padding: 0 1rem 0.5rem;
		border: $border;
		border-color: transparent;
		border-top: none;
		border-bottom-right-radius: $border-radius-small;
		border-bottom-left-radius: $border-radius-small;
		transition: border-color 200ms;
	}

	.options--expanded {
		border-color: $border-color;
	}

	&__table {
		margin: 1.5rem 1rem 0;
		background-color: $color-white;

		::v-deep {
			thead tr:not(.v-data-table__progress) th {
				vertical-align: text-top;
				font-size: 1rem;
				font-weight: bold;
				height: 2rem !important;
			}

			tbody tr:nth-of-type(even) {
				background-color: $color-table-bg;
			}

			tbody td {
				font-size: 1rem;
				border-color: transparent !important;
			}

			.v-data-table__expanded.v-data-table__expanded__content {
				box-shadow: none;

				.q-expand-table__expand-td {
					background-color: $color-white !important;
					padding: 0.25rem 0 !important;
	
					table tbody tr:last-of-type td {
						border-bottom: none;
					}

					table tr {
						background-color: transparent !important;
					}
				}
			}
		}
	}

	&__expand-wrapper {
		padding: 0.5rem;
		border-radius: $border-radius-small;
		color: #00000099;
		border: $border;
	}

	&__line-table {
		table {
			th, td {
				height: 24px !important;
			}
	   	}
	}

	&__line-flex {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.5rem;
		column-gap: 3rem;

		&-content {
			margin-left: 0.5rem;
			white-space: nowrap;
		}
	}

	::v-deep .cell-title {
		font-weight: 600;
	}
}
</style>
