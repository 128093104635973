// additional options:
// - for any row:		visible: false (the column will be shown in the expandable row on all screens)
// - for any row:		mobileVisible: true (the column will be displayed on the mobile screen)
export const agentTableConfig = [
	{ label: 'Bird-Dog', key: 'AgencyName', mobileVisible: true },
	{ label: 'Date Added', key: 'DateAdded', type: 'date', format: 'MM/DD/YYYY', toFormat: 'MM/DD/YYYY' },
	{ label: 'Date Updated', key: 'DateUpdated', type: 'date', format: 'MM/DD/YYYY', toFormat: 'MM/DD/YYYY' },
	{ label: 'Qualified', key: 'Qualified' },
	{ label: 'Status', key: 'Status' },
	{ label: '', key: 'tableActions', mobileVisible: true, width: 60, sortable: false },
]

export const staffTableConfig = [
	{ label: 'Referring Agent', key: 'ReferralSearch', mobileVisible: true },
	{ label: 'Bird-Dog', key: 'AgencyName' },
	{ label: 'Principal', key: 'Principal', sortable: false },
	{ label: 'Date Added', key: 'DateAdded', type: 'date', format: 'MM/DD/YYYY', toFormat: 'MM/DD/YYYY' },
	{ label: 'Date Updated', key: 'DateUpdated', type: 'date', format: 'MM/DD/YYYY', toFormat: 'MM/DD/YYYY' },
	{ label: 'Qualified', key: 'Qualified' },
	{ label: 'Status', key: 'Status' },
	{ label: '', key: 'tableActions', mobileVisible: true, width: 60, sortable: false },
]

export const targetMarketsOptions = [
	'Term Life',
	'Mortgage',
	'Protection',
	'Final Expense',
	'Advanced Markets',
	'Other',
]

export const statusOptions = [
	{ key: 'Submitted', label: 'Referral Submitted to B2B', labelShort: 'Submitted' },
	{ key: 'MtgComplete', label: 'B2B/Bird-Dogger Mtg Complete', labelShort: 'Mtg Complete' },
	{ key: 'IntroMtgComplete', label: 'Joint Intro Mtg/Bird-Dog Complete', labelShort: 'Intro Mtg Complete' },
	{ key: 'Onboarding', label: 'Onboarding In Process', labelShort: 'Onboarding' },
	{ key: 'Active', label: 'Active', labelShort: 'Active' },
	{ key: 'Terminated', label: 'Terminated', labelShort: 'Terminated' },
]

export const qualifiedOptions = [
	{ text: 'TBD', value: 'TBD' },
	{ text: 'Yes', value: 'Yes' },
	{ text: 'No', value: 'No' },
]
